<template>
  <page :title="'律师服务'">
  <article id="page-assistance" class="business_banner">
  <section class="container container-main">
    <div class="business-banner-head">
      <h1 class="title">律师服务</h1>
      <p class="desc">
        律师是指依法取得律师执业证书，接受委托或指定，为当事人提供法律服务的执业人员。律师队伍是社会主义法治工作队伍的重要组成部分，承担着维护当事人合法权益、维护法律正确实施、维护社会公平和正义的崇高使命。
      </p>
      <div class="business-apply-button mt-5">
        <a-button class="mr-3"><router-link to="/lawyer" target="_blank">预约办理</router-link></a-button>
      </div>
      <span class="business-banner-head-icon flyz-icon"></span>
    </div>
    <div class="bg_white w-1200 br-4 p-3">
    <div class="subtitle">
      <h2>律师服务指南</h2>
      <hr class="underline">
    </div>
    <div class="comm-html">
    <a-tabs
          default-active-key="condition"
          :animated="false"
          class="block-tabs mt-5"
        >
          <a-tab-pane key="condition" tab="律师业务范围">
            <div class="ov_text">
              <h4>律师的业务范围</h4>
              <p>一、律师可以从事下列业务</p>
              <p>（一）接受自然人、法人或者其他组织的委托，担任法律顾问；</p>
              <p>（二）接受民事案件、行政案件当事人的委托，担任代理人，参加诉讼；</p>
              <p>（三）接受刑事案件犯罪嫌疑人、被告人的委托或者依法接受法律援助机构的指派，担任辩护人，接受自诉案件自诉人、公诉案件被害人或者其近亲属的委托，担任代理人，参加诉讼；</p>
              <p>（四）接受委托，代理各类诉讼案件的申诉；</p>
              <p>（五）接受委托，参加调解、仲裁活动；</p>
              <p>（六）接受委托，提供非诉讼法律服务；</p>
              <p>（七）解答有关法律的询问、代写诉讼文书和有关法律事务的其他文书。</p>
              <p>二、律师在执业活动中不得有哪些行为</p>
              <p>下列行为是律师执业行为中不得出现的行为：</p>
              <p>（1）私自接受委托，私自向委托人收取费用，收受委托人的财物；</p>
              <p>（2）接受委托后，无正当理由的，拒绝辩护或者代理，但委托事项违法，委托人利用律师提供的服务从事违法活动或者委托人隐瞒事实的除外；</p>
              <p>（3）在同一案件中，为双方当事人担任代理人；</p>
              <p>（4）在同一案件中，同时为委托人及与委托人有利益冲突的第三人代理、辩护；</p>
              <p>（5）在与委托人依法解除委托关系后，在同一案件中担任有利益冲突的他方当事人的代理人；</p>
              <p>（6）在两个或者两个以上有利害关系得案件中，分别为有利益冲突的当事人代理、辩护的；</p>
              <p>（7）担任法律顾问期间，为法律顾问单位的对方当事人或者有其他利益冲突的当事人代、辩护；</p>
              <p>（8）接受当事人委托后，不认真履行职责，给委托人造成损失；</p>
              <p>（9）接受当事人委托后，擅自转委托他人代理；</p>
              <p>（10）接受当事人委托后，故意损害委托人的利益，或者与对方当事人、第三人恶意串通侵害委托人利益；</p>
              <p>（11）为阻挠委托人解除委托关系，威胁、恐吓委托人，或者无正当理由扣留委托人提供的材料；</p>
              <p>（12）超越委托权限，利用委托关系从事与委托代理的法律事务无关的活动；</p>
              <p>（13）在未征得委托人同意的情况下，接受对方当事人办理其他法律事务的委托，但办结委托事项后除外；</p>
              <p>（14）阻挠或者拒绝委托人再委托其他律师参与法律服务；</p>
              <p>（15）在明知的情况下为委托人非法的、不道德的或者具有欺诈性的要求或者行为提供服务和帮助；</p>
              <p>（16）接受对方当事人利益或者向其要求或约定利益；</p>
              <p>（17）非法阻止和干预对方当事人及其代理人进行的活动；</p>
              <p>（18）利用提供法律服务的便利牟取当事人争议的权益，或者接受对方当事人的财物；</p>
              <p>（19）与犯罪嫌疑人、被告人或者在押罪犯的近亲属或者其他人会见犯罪嫌疑人、被告人或者在押罪犯，或者借职务之便违反规定为被告人传递信件、钱物或者与案情有关的信息；</p>
              <p>（20）曾担任法官、检察官的律师，在离任后两年内担任诉讼代理人或者辩护人，或者担任其任职期间承办案件的代理人或者辩护人；</p>
              <p>（21）同时在两个或两个以上律师事务所执业，或者同时在律师事务所和其他法律服务机构执业；</p>
              <p>（22）执业期间以非律师身份从事法律服务；</p>
              <p>（23）为争揽业务，向委托人作虚假承诺；</p>
              <p>（24）利用媒体、广告或者其他方式进行不真实或者不适当的宣传；</p>
              <p>（25）以诋毁其他律师、律师事务所信誉或者支付介绍费等不正当手段争揽业务；</p>
              <p>（26）宣称自己与司法机关、行政机关或者其他具有社会管理职能组织及其工作人员具有密切关系，或者利用这种关系进行不正当竞争；</p>
              <p>（27）承办案件期间，在非工作时间、非工作场所，会见承办案件的法官、检察官、仲裁员或者其他有关工作人员，或者违反规定单方面会见法官、检察官、仲裁员或者其他有关工作人员；</p>
              <p>（28）向法官、检察官以及其他工作人员请客送礼或者行贿，或者指使、诱导当事人行贿；</p>
              <p>（29）提供虚假证据，隐瞒事实或者威胁、利诱他人提供虚假证据，隐瞒事实以及妨碍对方当事人合法取得证据；</p>
              <p>（30）向司法行政机关或者律师协会提供虚假材料、隐瞒重要事实或者有其他虚假行为；</p>
              <p>（31）泄露在执业活动中知悉的国家秘密和当事人的商业秘密或者个人隐私；</p>
              <p>（32）拒绝或者懈怠承担指派的法律援助义务；</p>
              <p>（33）扰乱法庭、仲裁庭秩序，干扰诉讼、仲裁活动的正常进行；</p>
              <p>（34）其他违法或者有悖律师职业道德、公民道德规范的行为，严重损害律师职业形象。</p>
            </div>
          </a-tab-pane>
          <a-tab-pane key="material" tab="何时聘请律师">
            <div class="ov_text">
              <h4>关于律师的收费标准</h4>
              <p>1.收费依据</p>
              <p>2014年之前，按照规定，律师服务收费由各省、自治区、直辖市物价部门会同司法行政机关制定服务收费管理实施办法，实行政府指导价和市场调节价相结合的收费办法和标准。按照国家发展改革委《关于放开部分服务价格意见的通知》（发改价格[2014]2755号）文件精神，律师服务（刑事案件辩护和部分民事诉讼、行政诉讼、国家赔偿案件代理除外），其他实行市场调节价。</p>
              <p>2.确定律师收费标准的考虑因素主要有：</p>
              <p>（1）需要的工作时间；</p>
              <p>（2）法律事务的难易程度；</p>
              <p>（3）办理法律事务所需律师人数和承办律师的执业经历和业务水平；</p>
              <p>（4）委托人的承受能力和所在地经济发展状况；</p>
              <p>（5）律师可能承担的风险和责任；</p>
              <p>（6）律师的社会信誉、职称级别和工作水平；</p>
              <p>（7）案件标的；</p>
              <p>（8）办理法律事务所需的其他必要成本支出。</p>
              <p>3.在为委托人服务过程中发生的下列费用，由委托人另行支付，另有约定的除外：</p>
              <p>（1）司法机关、行政机关、仲裁机构、鉴定机构、公证机关等部门收取的费用；</p>
              <p>（2）合理的差旅费；</p>
              <p>（3）为办理委托事项支付的查询费；</p>
              <p>（4）经委托人同意支付的专家论证费、翻译费；</p>
              <p>（5）经委托人同意支付的其他费用。</p>
              <p>4.律师费的计费方法</p>
              <p>律师服务收费可以根据不同的服务内容、不同的服务方式，采取期间固定收费、计件收费、按标的额比例收费和计时收费、风险代理收费和协商收费等方式。具体收费办法及数额，由双方根据规定在（聘请律师合同）中约定。</p>
              <p>5、不得实行风险代理收费的案件：</p>
              <p>（1）婚姻、继承案件；</p>
              <p>（2）请求给予社会保险待遇或者最低生活保障待遇的案件；</p>
              <p>（3）请求给付赡养费、抚养费、扶养费、抚恤金、救济金、工伤赔偿的案件；</p>
              <p>（4）请求支付劳动报酬的案件等；</p>
              <p>（5）刑事诉讼案件、行政诉讼案件、国家赔偿案件以及涉及安全事故、环境污染、征地拆迁赔偿（补偿）等公共利益的群体性诉讼案件</p>
            </div>
          </a-tab-pane>
          <a-tab-pane key="range" tab="收费标准">
            <div class="ov_text">
              <h4>关于律师的收费标准</h4>
              <p>1.收费依据</p>
              <p>2014年之前，按照规定，律师服务收费由各省、自治区、直辖市物价部门会同司法行政机关制定服务收费管理实施办法，实行政府指导价和市场调节价相结合的收费办法和标准。按照国家发展改革委《关于放开部分服务价格意见的通知》（发改价格[2014]2755号）文件精神，律师服务（刑事案件辩护和部分民事诉讼、行政诉讼、国家赔偿案件代理除外），其他实行市场调节价。</p>
              <p>2.确定律师收费标准的考虑因素主要有：</p>
              <p>（1）需要的工作时间；</p>
              <p>（2）法律事务的难易程度；</p>
              <p>（3）办理法律事务所需律师人数和承办律师的执业经历和业务水平；</p>
              <p>（4）委托人的承受能力和所在地经济发展状况；</p>
              <p>（5）律师可能承担的风险和责任；</p>
              <p>（6）律师的社会信誉、职称级别和工作水平；</p>
              <p>（7）案件标的；</p>
              <p>（8）办理法律事务所需的其他必要成本支出。</p>
              <p>3.在为委托人服务过程中发生的下列费用，由委托人另行支付，另有约定的除外：</p>
              <p>（1）司法机关、行政机关、仲裁机构、鉴定机构、公证机关等部门收取的费用；</p>
              <p>（2）合理的差旅费；</p>
              <p>（3）为办理委托事项支付的查询费；</p>
              <p>（4）经委托人同意支付的专家论证费、翻译费；</p>
              <p>（5）经委托人同意支付的其他费用。</p>
              <p>4.律师费的计费方法</p>
              <p>律师服务收费可以根据不同的服务内容、不同的服务方式，采取期间固定收费、计件收费、按标的额比例收费和计时收费、风险代理收费和协商收费等方式。具体收费办法及数额，由双方根据规定在（聘请律师合同）中约定。</p>
              <p>5、不得实行风险代理收费的案件：</p>
              <p>（1）婚姻、继承案件；</p>
              <p>（2）请求给予社会保险待遇或者最低生活保障待遇的案件；</p>
              <p>（3）请求给付赡养费、抚养费、扶养费、抚恤金、救济金、工伤赔偿的案件；</p>
              <p>（4）请求支付劳动报酬的案件等；</p>
              <p>（5）刑事诉讼案件、行政诉讼案件、国家赔偿案件以及涉及安全事故、环境污染、征地拆迁赔偿（补偿）等公共利益的群体性诉讼案件</p>
            </div>
          </a-tab-pane>
          <a-tab-pane key="form" tab="注意事项">
            <div class="ov_text">
              <h4>注意事项</h4>
              <p>一、与律师事务所签订服务合同时要注意什么</p>
              <p>1.全面了解律师事务所及承办律师的从业资质、服务标准、收费标准、联系方式等信息，理性慎重地作出选择。</p>
              <p>2.应与律师事务所签订书面委托协议，变更或增加委托事项、承办律师和律师事务所时也应签订书面协议。委托人务必注意保留自己与律师事务所或者承办律师签订的委托协议及材料。</p>
              <p>3.在签约前查看承办律师的《律师执业证》，律师事务所及承办律师应依照法律规定和委托协议约定提供法律服务，并对委托事项及相关问题给予充分、及时、准确的提示、解答。律师事务所和承办律师应当谨慎、诚实、客观地告知委托人拟委托事项可能出现的法律风险，包括败诉风险、执行风险等，让委托人了解自己在各个诉讼环节可能遇到的风险。</p>
              <p>二、找正规律师请注意"两查看两不信"</p>
              <p>1.是要查看律师的律师执业证。</p>
              <p>该证件由司法部统一监制，加盖各省、自治区、直辖市司法厅印章，记载了律师的姓名、所在律师事务所名称、执业证号、身份证号、法律职业资格证号（律师资格证号）以及律师的年度考核情况。当事人可以通过宁夏12348法网、宁夏司法行政网、宁夏律师协会网查询律师的基本信息。</p>
              <p>2.是查看律师事务所的名称场地。</p>
              <p>办理委托业务最好到律师事务所进行，或者提前到事务所实地核实查证。律师事务所的《律师事务所执业许可证》或《律师事务所分所执业许可证》在其办公场所内悬挂。</p>
              <p>正规律师事务所的名称为一般为"省（自治区、直辖市）行政区划名+字号+律师事务所"三部分组成，分所名称省（自治区、直辖市）行政区划名+总所字号+分所所在地市、县行政区划名+律师事务所"四部组成。我区律师事务所的名称为宁夏**律师事务所，外省在宁分所名称一般为北京**（银川）律师事务所。</p>
              <p>重要提示：基层法律服务所、法律咨询机构、法律咨询公司等不是律师事务所。</p>
              <p>3.是不要轻信"包打赢"。</p>
              <p>诉讼有败诉风险，律师只能以事实为依据、法律为准绳，严格依照有关法律法规代理案件。不要轻易听信"有关系"、"包打赢"、"花钱疏通关系"等虚假宣传。</p>
              <p>4.是不要轻信"打白条"。</p>
              <p>委托律师代理案件一定要与其执业的律师事务所签订委托协议，并加盖律师事务所公章。收费应当由律师事务所统一收取并开具发票，不要轻信口头约定或者直接向律师支付代理费。</p>
              <p>三、聘请律师的合同与签署委托书一般要求是什么？</p>
              <p>决定委托律师事务所及律师承办您的法律事务时，应当与签订一份聘请律师合同；合同一般应当包含如下事项：</p>
              <p>（1）合同应当约定具体而明确的办理事项内容。</p>
              <p>（2）律师事务所根据约定指定的承办律师是谁。</p>
              <p>（3）律师事务所应当明确对律师的授权是一般代理还是特别授权。</p>
              <p>（4）合同应当明确律师事务所及承办律师（受托人）的义务。</p>
              <p>（5）合同应当明确当事人的义务。</p>
              <p>（6）合同应当约定支付的律师费用。</p>
              <p>（7）合同有效期限。</p>
              <p>（8）委托双方的违约责任。</p>
              <p>（9）委托双方发生争议的解决办法。</p>
              <p>在订立（聘请律师合同）的同时，应当签署委托书。主要包括具体的委托事项、律师的代理权限等内容</p>
            </div>
          </a-tab-pane>
          <a-tab-pane key="prove" tab="常见问题">
            <div class="ov_text">
              <h4>律师服务的常见问题</h4>
              <p>1.如果我的官司输了，律师费可以退吗？</p>
              <p>律师的服务收费是整个委托代理过程中律师所付出辛勤劳动的价值体现，一般不会约定以最终诉求能否实现、官司输赢作为付费标准。这一方面是为了尊重律师在委托过程中付出的劳动，另一方面也是为了保护当事人，避免一些律师以低价恶意竞争而牺牲处理案件的质量。当然，您也可以在与律所签订委托合同时依据合同法的规定就退还律师费的情况作出约定。</p>
              <p>2.如果我不满意律师或者不想起诉了，我能够要求终止委托代理关系并要求退还律师费吗？</p>
              <p>在委托过程中，您有任何不满意律师工作的情况或者因自身情况发生变化不再起诉或撤诉的，都可以要求终止委托代理关系。但是，律师费是否因终止委托代理关系而退还，主要还是依据您与律师事务所签订的委托代理合同的约定。您也可以和律师事务所及律师协商解决。</p>
              <p>3.律师费都包括哪些项目？如何向律师事务所支付律师费？</p>
              <p>律师费一般包括：</p>
              <p>（1）律师承办业务开支，包括为您提供该项服务时可能发生的鉴定费、翻译费、资料费、复印费、通讯费及其他必须开支的费用，按实际发生额支付。</p>
              <p>（2）律师事务所就办理委托事项产生的差旅费、调查费等费用，应该在《委托代理合同》中约定承担，与委托人据实进行结算。承办案件差旅费、调查费用承担问题，应该在《委托代理合同》中予以约定，并依照程序与委托人据实进行结算。除此之外，律师不得收取律师服务费以外的其他任何费用。</p>
              <p>（3）律师事务所在提供法律服务过程中代委托人支付的诉讼费、仲裁费、鉴定费、公证费等不属于律师服务费的有关诉讼成本费用，由委托人另行支付。 </p>
              <p>依照聘请律师合同的约定，当事人（委托人）应当向律师事务所支付律师费，并由律师事务所出具发票。</p>
              <p>4.哪些人员不得以律师名义执业？</p>
              <p>下列人员不得以律师名义执业，或者为牟取经济利益从事诉讼代理或者辩护业务：</p>
              <p>（1）仅考取《律师资格证书》或《法律职业资格证书》的人员；</p>
              <p>（2）持有《实习律师培训结业证》的人员；</p>
              <p>（3）持有《实习律师工作证》的人员；</p>
              <p>（4）持有《律师助理证》的人员；</p>
              <p>（5）律师事务所的其他工作人员；</p>
              <p>（6）律师执业证书未经省、自治区、直辖市司法行政部门当年注册的人员；</p>
              <p>（7）基层法律服务工作者；</p>
              <p>（8）各级人民代表大会常务委员会组成人员，以及行政机关、司法机关、军事机关等国家机关的现职工作人员；</p>
              <p>（9）在受到停止执业处罚期间的律师；</p>
              <p>（10）在律师事务所被停业整顿期间或者注销后继续以原所名义执业的律师；</p>
              <p>（11）其他公民、社会组织。 </p>
              <p>注：公职律师证、公司律师不得面向社会从事有偿法律服，法律援助律师只能承办法律援助案件</p>
            </div>
          </a-tab-pane>
        </a-tabs>
    </div>
        <IntelligentConsult :value="lawyerUrl"></IntelligentConsult>
        <div class="mt-5 overflow-hidden tab news_pbulic">
          <div class="col-lg-6 col-md-6 col-xs-6 float-left pl-0">
            <a-tabs default-active-key="1">
              <a-tab-pane key="1" tab="服务动态">
                <div  class="tab-pane">
                    <Collection  name="post"   :query="{ target: '0176893ec6a58a838128768809590a43' }"
                      v-slot="{ collection }"  :limit=9 >
                    <Article :refObj="collection"></Article>
                    </collection>
                </div>
              </a-tab-pane>
              <a-tab-pane key="2" tab="惩戒通报">
                <div  class="tab-pane">
              <Collection  name="post" :query="{ target: '0176893e844e8a838128768809590a3f' }"
                v-slot="{ collection }"  :limit=9 >
              <Article :refObj="collection"></Article>
              </collection>
              </div>
              </a-tab-pane>
            </a-tabs>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-6 float-right p-0 pl-4">
            <a-tabs default-active-key="1">
              <a-tab-pane key="1" tab="律师服务案件">
                <div  class="tab-pane">
              <Collection  name="case" :query="{ recase_type: '律师服务' }"
                v-slot="{ collection }"  :limit=9 >
              <Case :refObj="collection"></Case>
              </collection>
              </div>
              </a-tab-pane>
              <a-tab-pane key="2" tab="政策法规">
                <div  class="tab-pane">
              <Collection  name="law" :query="{ law_ctype: '律师服务' }"
                v-slot="{ collection }"  :limit=9 >
              <Law :refObj="collection"></Law>
              </collection>
              </div>
              </a-tab-pane>
            </a-tabs>
          </div>
        </div>
    <div class="advs mt-3"><a href="http://117.156.20.231:9527/login.jsp" target="_blank"><img src="../../assets/images/lawyerserve_banner.jpg"/></a></div>
        <div class="mt-3 mechanism_list">
          <a-tabs
            default-active-key="org"
            :animated="false"
            class="tabs custom-tabs"
          >
            <a-tab-pane key="org" tab="律师事务所">
              <div class="screen_box">
                <City :value= providerQuery.provider_type @change="handleCityChange"></City>
                <div class="tabs_search">
                  <p class="keyword">关键字查询</p>
                  <div>
                    <div>
                      <a-input-search
                        style="width: 700px"
                        placeholder="请输入关键字搜索"
                        enter-button="搜索"
                        size="large"
                        @search="
                          (value) => (providerQuery.provider_name = value)
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Collection
                name="provider"
                :page="provider.current"
                :query="{
                  city: providerQuery.city,
                  area: providerQuery.area,
                  business_type: providerQuery.professional,
                  provider_name: providerQuery.provider_name,
                  provider_type: providerQuery.provider_type
                }"
                v-slot="{ collection, count }"
              >
                <a-row :gutter="[16, 16]" class="mt-5">
                  <a-col
                    :span="6"
                    v-for="item in collection"
                    :key="item.provider_id"
                  >
                    <Org
                      :post="item"
                    >
                    </Org>
                  </a-col>
                </a-row>

                <a-pagination
                  class="mb-5"
                  show-quick-jumper
                  :current="provider.current"
                  :defaultPageSize="provider.defaultPageSize"
                  :total="count"
                  @change="onChange"
                />
              </Collection>
            </a-tab-pane>
            <a-tab-pane key="personnel" tab="律师">
              <div class="screen_box">
                <City @change="handleCityChange"></City>
                <div class="tabs_search">
                  <p class="keyword">关键字查询</p>
                  <div>
                    <div>
                      <a-input-search
                        style="width: 700px"
                        placeholder="请输入关键字搜索"
                        enter-button="搜索"
                        size="large"
                        @search="
                          (value) => (providerQuery.person_name = value)
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Collection
                name="person"
                :page="person.current"
                :query="{
                  city: providerQuery.city,
                  area: providerQuery.area,
                  person_name: providerQuery.person_name,
                  person_type: '律师人员'
                }"
                v-slot="{ collection, count }"
              >
                <a-row :gutter="[16, 16]" class="mt-5">
                  <a-col
                    :span="6"
                    v-for="item in collection"
                    :key="item.person_id"
                    :face="item.face"
                  >
                    <Person
                      :post="item"
                    >
                    </Person>
                  </a-col>
                </a-row>
                <a-pagination
                  class="mb-5"
                  show-quick-jumper
                  :current="person.current"
                  :defaultPageSize="person.defaultPageSize"
                  :total="count"
                  @change="onChange"
                />
              </Collection>
            </a-tab-pane>
          </a-tabs>
          <div class="tab-content">
          </div>
        </div>
    </div>
  </section>
</article>
  </page>
</template>
<script>
import Collection from '@/components/Collection'
import Page from '@/components/base/Page'
import Org from '@/components/business/Org'
import City from '@/components/business/City'
import Person from '@/components/business/Person'
import Article from '../../components/base/Article.vue'
import Case from '../../components/base/Case.vue'
import Law from '../../components/base/Law.vue'
import IntelligentConsult from '@/components/business/IntelligentConsult'
export default {
  components: {
    Collection,
    Page,
    Org,
    City,
    Person,
    Article,
    IntelligentConsult,
    Case,
    Law
  },
  data () {
    return {
      lawyerUrl: '/lawyer',
      provider: {
        current: 1,
        defaultPageSize: 12
      },
      person: {
        current: 1,
        defaultPageSize: 12
      },
      providerQuery: {
        city: '',
        area: '',
        professional: '',
        provider_name: '',
        person_name: '',
        provider_type: '律师事务所'
      }
    }
  },
  methods: {
    onChange (page, pageSize) {
      this.provider.current = page
    },
    handleCityChange (obj) {
      this.providerQuery.city = obj.city
      this.providerQuery.area = obj.area
      this.providerQuery.professional = obj.professional
    }
  }
}
</script>
